import {
  GET_ALL_SPEAKERS,
  GET_ALL_VISITORS,
  GET_SPEAKERS_WITH_PAGINATION,
  GET_VISITORS_WITH_PAGINATION,
  SET_CURRENT_SPEAKER,
  SET_CURRENT_VISITOR,
} from "redux-thunk/constants/Users";

const initState = {
  visitors: [],
  speakers: [],
  currentVisitor: {},
  currentSpeaker: {},
};

const users = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_VISITORS:
      return {
        ...state,
        visitors: action.payload?.data || [],
      };
    case GET_VISITORS_WITH_PAGINATION:
      return {
        ...state,
        visitors: action.payload?.data?.result || [],
      };
    case GET_ALL_SPEAKERS:
      return {
        ...state,
        speakers: action?.payload?.data || [],
      };
    case GET_SPEAKERS_WITH_PAGINATION:
      return {
        ...state,
        speakers: action?.payload?.data?.result || [],
      };
    case SET_CURRENT_VISITOR:
      return {
        ...state,
        currentVisitor: action?.payload?.data || [],
      };
    case SET_CURRENT_SPEAKER:
      return {
        ...state,
        currentSpeaker: action?.payload?.data || [],
      };
    default:
      return state;
  }
};

export default users;
