const dev = {
  // FRONT_URL: 'https://pms.rawdalsaleheen.com/#/',
  // API_ENDPOINT_URL: 'https://pms.rawdalsaleheen.com/api/',
  // LP_API_ENDPOINT_URL: 'http://127.0.0.1:4000',
  FRONT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  API_ENDPOINT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  LP_API_ENDPOINT_URL: "https://betasite.rawdalsaleheen.edu.kw:8000",
};

const prod = {
  // FRONT_URL: 'https://pms.rawdalsaleheen.com/#/',
  // API_ENDPOINT_URL: 'https://pms.rawdalsaleheen.com/api/',
  // LP_API_ENDPOINT_URL: 'https://betasite.rawdalsaleheen.edu.kw:8000',
  FRONT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  API_ENDPOINT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  LP_API_ENDPOINT_URL: "https://betasite.rawdalsaleheen.edu.kw:8000",
};

const staging = {
  // FRONT_URL: 'https://pms.rawdalsaleheen.com/#/',
  // API_ENDPOINT_URL: 'https://pms.rawdalsaleheen.com/api/',
  // LP_API_ENDPOINT_URL: 'http://127.0.0.1:4000',
  FRONT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  API_ENDPOINT_URL: "http://rawdalsaleheen.edu.kw:9999/api/v-1/admin",
  LP_API_ENDPOINT_URL: "http://127.0.0.1:4000",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'staging':
      return staging;
    default:
      break;
  }
};

export const env = getEnv();