import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Input, Select, message } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from './Logo';
// import NavNotification from './NavNotification';
// import NavLanguage from './NavLanguage';
import NavProfile from './NavProfile';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import { useDispatch, useSelector } from 'react-redux';
import AppLocale from "lang";
import { Link, Redirect, useHistory } from "react-router-dom";

const { Header } = Layout;

export const HeaderNav = props => {
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();

  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)

  // for Language use this 
  const locale = useSelector(state => state.theme.locale)
  const currentAppLocale = (title) => AppLocale[locale]?.messages[title]; // this main Varibale (currentAppLocale) is use for Language Change
  const userData = useSelector(state => state.auth.userData)

  var [searchFor, setSearchFor] = useState(null)
  var [searchValue, setSearchValue] = useState('')

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })

  const navigateFunction = (type) => {
    // console.log("type ====", type);
  }

  const searchFunction = () => {
    console.log(userData?.is_admin)
    const key = 'updatable';
    if (searchFor && searchValue && userData?.is_admin ) {
      if (searchFor == 'student') {
        window.location.href = '#/app/student-affairs/student-list?searchedName=' + searchValue
      } else if (searchFor == 'studentParent') {
        window.location.href = '#/app/student-affairs/parent-list?searchedName=' + searchValue
      } else if (searchFor == 'employee') {
        window.location.href = '#/app/human-resource/employee-list?searchedName=' + searchValue
      }
      // setSearchFor(null); setSearchValue(null)
    } else {
      message.error({ content:currentAppLocale("Your.are.not.allowed.to.access.this.page"), key, duration: 2 });
    }

  }

  const selectBefore = (
    <Select virtual={false} value={searchFor ? searchFor : null} placeholder={currentAppLocale('Select.an.option')} onChange={(value) => { setSearchFor(value); navigateFunction(value) }} style={{ width: 120 }}>
      <Option value={'student'}>{currentAppLocale('Header.Search.Student')}</Option>
      <Option value={'employee'}>{currentAppLocale('Header.Search.Employee')}</Option>
      <Option value={'studentParent'}>{currentAppLocale('Header.Search.Parent')}</Option>
    </Select>
  );

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child q" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }

            </ul>
          </div>
          <div className="nav-right">
            {/* <NavNotification /> */}
            {/* <NavLanguage /> */}
            {
              userData?.is_admin ? <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isMobile ?
                  <li className="ant-menu-item ant-menu-item-only-child m" onClick={() => { onSearchActive() }}>
                    <SearchOutlined />
                  </li>
                  :
                  <li className="ant-menu-item ant-menu-item-only-child d" style={{ cursor: 'auto' }}>
                    {/* <SearchInput mode={mode} isMobile={isMobile} /> */}
                    <Input value={searchValue ? searchValue : null}
                      allowClear
                      onPressEnter={() => searchFunction()}
                      onReset={() => { setSearchFor(null); setSearchValue(null) }}
                      style={{ marginTop: '15px' }}
                      addonBefore={selectBefore}
                      placeholder={currentAppLocale('Search.Name')}
                      onChange={(value) => setSearchValue(value.target.value)}
                      onSubmitCapture={() => searchFunction()}
                      addonAfter={<SearchOutlined onClick={() => searchFunction()} />} />
                  </li>
              }
            </ul> : ""
            }
            
            <NavProfile />
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);