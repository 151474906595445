import {
  GET_ALL_SESSIONS,
  GET_SESSIONS_VISITORS,
  GET_SESSIONS_VISITORS_STATUS,
  GET_SESSIONS_WITH_PAGINATION,
  SET_CURRENT_SESSION,
} from "redux-thunk/constants/Sessions";

const initState = {
  sessions: [],
  currentSession: {},
  sessionsVisitors: {},
  sessionsVisitorsStatus: {},
};

const sessions = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_SESSIONS:
      return {
        ...state,
        sessions: action?.payload?.data || [],
      };
    case GET_SESSIONS_WITH_PAGINATION:
      return {
        ...state,
        sessions: action?.payload?.data?.result || [],
      };
    case SET_CURRENT_SESSION:
      return {
        ...state,
        currentSession: action?.payload?.data || {},
      };
    case GET_SESSIONS_VISITORS:
      return {
        ...state,
        sessionsVisitors: action?.payload?.data || {},
      };
    case GET_SESSIONS_VISITORS_STATUS:
      return {
        ...state,
        sessionsVisitorsStatus: action?.payload?.data || {},
      };
    default:
      return state;
  }
};

export default sessions;
