export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_EMPLOYEE_BY_ID = 'GET_EMPLOYEE_BY_ID';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';

// Employee Document
export const GET_EMPLOYEE_DOCS = 'GET_EMPLOYEE_DOCS';
export const GET_EMPLOYEE_DOCS_DETAILS = 'GET_EMPLOYEE_DOCS_DETAILS';

export const EMPLOYEE_SEARCH = 'EMPLOYEE_SEARCH';
export const EMPLOYEE_COUNTRY_LIST = 'EMPLOYEE_COUNTRY_LIST';
export const EMPLOYEE_BIRTH_PASSPORT_GRADUATION_COUNTRY_LIST =
  'EMPLOYEE_BIRTH_PASSPORT_GRADUATION_COUNTRY_LIST';

export const GETEMPLOYEES = 'GETEMPLOYEES';
export const GET_ALL_EMPLOYEE_REQUESTS = "GET_ALL_EMPLOYEE_REQUESTS";
export const GET_ALL_REQUEST_TYPES = "GET_ALL_REQUEST_TYPES";
export const SET_CURRENT_REQUEST = "SET_CURRENT_REQUEST";
export const GET_ALL_ACTIVE_EMPLOYEES = "GET_ALL_ACTIVE_EMPLOYEES";
export const GET_EMPLOYEE_TOTALS = "GET_EMPLOYEE_TOTALS";
export const GET_ALL_REQUESTS_OPTIONS = "GET_ALL_REQUESTS_OPTIONS"
export const SET_CURRENT_EMPLOYEE = "SET_CURRENT_EMPLOYEE"
export const GET_EMPLOYEES_WITH_LIBRARY_SUMMARY =
  "GET_EMPLOYEES_WITH_LIBRARY_SUMMARY";
