import {
  DashboardOutlined, AppstoreOutlined, BulbOutlined, UsergroupDeleteOutlined, FileOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FaHospital, FaMeetup, FaStethoscope, FaUser } from "react-icons/fa";
import { HiNewspaper, HiOutlineLibrary, HiOutlineUserGroup } from "react-icons/hi";
import { SiBookstack } from 'react-icons/si';
import { TbBooks } from 'react-icons/tb';

const dashboardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/apps/`,
        title: "Users",
        icon: FaUser,
        breadcrumb: true,
        submenu: [
          {
            key: "visitors",
            path: `${APP_PREFIX_PATH}/users/visitors`,
            title: "Visitors",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "speakers",
            path: `${APP_PREFIX_PATH}/users/speakers`,
            title: "Speakers",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "sessions-group",
        path: `${APP_PREFIX_PATH}/apps/`,
        title: "Sessions",
        icon: HiOutlineUserGroup,
        breadcrumb: true,
        submenu: [
          {
            key: "sessions",
            path: `${APP_PREFIX_PATH}/sessions-group/sessions`,
            title: "Sessions",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "sessions-visitors",
            path: `${APP_PREFIX_PATH}/sessions-group/session-visitors`,
            title: "Sessions.Visitors",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "latest-news",
        path: `${APP_PREFIX_PATH}/apps/`,
        title: "News",
        icon: HiNewspaper,
        breadcrumb: true,
        submenu: [
          {
            key: "news",
            path: `${APP_PREFIX_PATH}/latest-news/news`,
            title: "News",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...dashboardNavTree];
export default navigationConfig;