import {
  GET_ALL_NEWS,
  GET_NEWS_BY_ID,
  GET_NEWS_WITH_PAGINATION,
} from "redux-thunk/constants/News";

const initState = {
  news: [],
  currentNews: {},
};

const news = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_NEWS:
      return {
        ...state,
        news: action?.payload?.data || [],
      };
    case GET_NEWS_WITH_PAGINATION:
      return {
        ...state,
        news: action?.payload?.data || [],
      };
    case GET_NEWS_BY_ID:
      return {
        ...state,
        currentNews: action?.payload?.data || [],
      };
    default:
      return state;
  }
};

export default news;
