import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';
import AppLocale from "lang";

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const currentAppLocale = (title) => AppLocale[this.props.locale]?.messages[title];

    return (
      <>
        <Menu className="d-flex align-item-center" mode="horizontal" style={{ width: 150, marginLeft: 10, marginRight: 10 }}>
          <Menu.Item key="panel" style={{ width: '90%' }} onClick={this.showDrawer}>
            <span><SettingOutlined className="nav-icon mr-0" /></span>&nbsp; <span>{currentAppLocale('Setting')}</span>
          </Menu.Item>
        </Menu>
        <Drawer
          title={currentAppLocale("Theme.Config")}
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);