import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import AsyncStorage from '@react-native-community/async-storage'
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';



const persistConfig = {
  key: 'root',
  // storage: localStorage
  storage: storage

}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, compose(
  composeWithDevTools (applyMiddleware(thunk)),
  process.env.NODE_ENV === 'development' && window.devToolsExtension ? window.devToolsExtension() : f => f
))
export const persistor = persistStore(store);


// const initialState = {}


// export const store = createStore(rootReducer, compose(
//   applyMiddleware(thunk),
//   process.env.NODE_ENV === 'development' && window.devToolsExtension ? window.devToolsExtension() : f => f
// ))

