import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} /> */}
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./Users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sessions-group`}
          component={lazy(() => import(`./Sessions`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/latest-news`}
          component={lazy(() => import(`./news`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/home/statistics`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
