import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import { useSelector } from 'react-redux';
import { signOut } from 'redux-thunk/actions/Auth'
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle} = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [permission, setPermission] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const {userData} = useSelector(state => state.auth);
  useEffect(() => {
    setPermission(userData.roles);
    setIsAdmin(userData.is_admin);
  });
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  };
  return (
    // <Menu
    //   theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
    //   mode="inline"
    //   style={{height: "100%", borderRight: 0}}
    //   defaultSelectedKeys={[routeInfo?.key]}
    //   defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
    //   className={hideGroupTitle ? "hide-group-title" : ""}>
    //   {navigationConfig.map((menu) =>
    //     menu.submenu.length > 0 ? (
    //       <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
    //         {permission === null ? '' : menu.submenu.filter(item => permission?.groups?.some(group => (group['slug'] === item.key)) || isAdmin)
    //           .map((subMenuFirst) =>
    //             subMenuFirst.submenu.length > 0 ? (
    //               <SubMenu
    //                 icon={
    //                   subMenuFirst.icon ? (
    //                     <Icon type={subMenuFirst?.icon}/>
    //                   ) : null
    //                 }
    //                 key={subMenuFirst.key}
    //                 title={setLocale(localization, subMenuFirst.title)}
    //               >
    //                 {
    //                   subMenuFirst.submenu.filter(item => {
    //                       const m = permission.groups.find(group => (group['slug'] === subMenuFirst.key));
    //                       const s = m ? m.PermissionsPages.findIndex(per => per['slug'] === item['key']) !== -1 : false;
    //                       return s || isAdmin;
    //                     }
    //                   ).map((subMenuSecond) => (
    //                     <Menu.Item key={subMenuSecond.key}>
    //                       {subMenuSecond.icon ? (<Icon type={subMenuSecond?.icon}/>) : null}
    //                       <span>
    //                         {setLocale(localization, subMenuSecond.title)}
    //                       </span>
    //                       <Link onClick={() => closeMobileNav()} to={subMenuSecond.path}/>
    //                     </Menu.Item>
    //                   ))}
    //               </SubMenu>
    //             ) : (
    //               <Menu.Item key={subMenuFirst.key}>
    //                 {subMenuFirst.icon ? <Icon type={subMenuFirst.icon}/> : null}
    //                 <span>{setLocale(localization, subMenuFirst.title)}</span>
    //                 <Link onClick={() => closeMobileNav()} to={subMenuFirst.path}/>
    //               </Menu.Item>
    //             )
    //           )}
    //       </Menu.ItemGroup>
    //     ) : (
    //       <Menu.Item key={menu.key}>
    //         {menu.icon ? <Icon type={menu?.icon}/> : null}
    //         <span>{setLocale(localization, menu?.title)}</span>
    //         {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path}/> : null}
    //       </Menu.Item>
    //     )
    //   )}
    // </Menu>

    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig?.map((menu) =>
        menu?.submenu?.length > 0 ? (
          <Menu.ItemGroup>
            {menu?.submenu?.map((subMenuFirst) =>
              subMenuFirst?.submenu?.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst?.submenu?.map((subMenuSecond) =>
                    subMenuSecond.submenu.length > 0 ? (
                      <SubMenu
                        icon={
                          subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null
                        }
                        key={subMenuSecond.key}
                        title={setLocale(localization, subMenuSecond.title)}
                      >
                        {subMenuSecond?.submenu?.map((subMenuThird) =>
                          subMenuSecond?.submenu?.length > 0 ? (
                            <Menu.Item key={subMenuThird.key}>
                              {subMenuThird.icon ? (
                                <Icon type={subMenuThird?.icon} />
                              ) : null}
                              <span>
                                {setLocale(localization, subMenuThird?.title)}
                              </span>
                              {subMenuThird.path ? (
                                <Link
                                  onClick={() => closeMobileNav()}
                                  to={subMenuThird.path}
                                />
                              ) : null}
                            </Menu.Item>
                          ) : null
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond?.title)}
                        </span>
                        {subMenuSecond.path ? (
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuSecond.path}
                          />
                        ) : null}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst?.title)}</span>
                  {subMenuFirst.path ? (
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  ) : null}
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>

    // <Menu
    //   theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
    //   mode="inline"
    //   style={{ height: "100%", borderRight: 0 }}
    //   defaultSelectedKeys={[routeInfo?.key]}
    //   defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
    //   className={hideGroupTitle ? "hide-group-title" : ""}
    // >
    //   {navigationConfig.map((menu) =>
    //     menu.submenu.length > 0 ? (
    //       <Menu.ItemGroup>
    //         {console.log(menu)}
    //         {permission === null
    //           ? ""
    //           : menu.submenu
    //               .filter(
    //                 (item) =>
    //                   permission?.groups?.some(
    //                     (group) => group["slug"] === item.key
    //                   ) || isAdmin
    //               )
    //               .map((subMenuFirst) =>
    //                 subMenuFirst.submenu.length > 0 ? (
    //                   <SubMenu
    //                     icon={
    //                       subMenuFirst.icon ? (
    //                         <Icon type={subMenuFirst?.icon} />
    //                       ) : null
    //                     }
    //                     key={subMenuFirst.key}
    //                     title={setLocale(localization, subMenuFirst.title)}
    //                   >
    //                     {subMenuFirst.submenu
    //                       .filter((item) => {
    //                         const m = permission.groups.find(
    //                           (group) => group["slug"] === subMenuFirst.key
    //                         );
    //                         const s = m
    //                           ? m.PermissionsPages.findIndex(
    //                               (per) => per["slug"] === item["key"]
    //                             ) !== -1
    //                           : false;
    //                         return s || isAdmin;
    //                       })
    //                       .map((subMenuSecond) =>
    //                         subMenuSecond.submenu.length > 0 ? (
    //                           <SubMenu
    //                             icon={
    //                               subMenuSecond.icon ? (
    //                                 <Icon type={subMenuSecond?.icon} />
    //                               ) : null
    //                             }
    //                             key={subMenuSecond.key}
    //                             title={setLocale(
    //                               localization,
    //                               subMenuSecond.title
    //                             )}
    //                           >
    //                             {subMenuSecond.submenu
    //                               .filter((item) => {
    //                                 return true;
    //                                 // const m = permission.groups.find(group => (group['slug'] === subMenuSecond.key));
    //                                 // const s = m ? m.PermissionsPages.findIndex(per => per['slug'] === item['key']) !== -1 : false;
    //                                 // return s || isAdmin;
    //                               })
    //                               .map((subMenuThird) => (
    //                                 <Menu.Item key={subMenuThird.key}>
    //                                   {subMenuThird.icon ? (
    //                                     <Icon type={subMenuThird?.icon} />
    //                                   ) : null}
    //                                   <span>
    //                                     {setLocale(
    //                                       localization,
    //                                       subMenuThird?.title
    //                                     )}
    //                                   </span>
    //                                   {subMenuThird.path ? (
    //                                     <Link
    //                                       onClick={() => closeMobileNav()}
    //                                       to={subMenuThird.path}
    //                                     />
    //                                   ) : null}
    //                                 </Menu.Item>
    //                               ))}
    //                           </SubMenu>
    //                         ) : (
    //                           <Menu.Item key={subMenuSecond.key}>
    //                             {subMenuSecond.icon ? (
    //                               <Icon type={subMenuSecond?.icon} />
    //                             ) : null}
    //                             <span>
    //                               {setLocale(
    //                                 localization,
    //                                 subMenuSecond?.title
    //                               )}
    //                             </span>
    //                             {subMenuSecond.path ? (
    //                               <Link
    //                                 onClick={() => closeMobileNav()}
    //                                 to={subMenuSecond.path}
    //                               />
    //                             ) : null}
    //                           </Menu.Item>
    //                         )
    //                       )}
    //                   </SubMenu>
    //                 ) : (
    //                   <Menu.Item key={subMenuFirst.key}>
    //                     {subMenuFirst.icon ? (
    //                       <Icon type={subMenuFirst?.icon} />
    //                     ) : null}
    //                     <span>
    //                       {setLocale(localization, subMenuFirst?.title)}
    //                     </span>
    //                     {subMenuFirst.path ? (
    //                       <Link
    //                         onClick={() => closeMobileNav()}
    //                         to={subMenuFirst.path}
    //                       />
    //                     ) : null}
    //                   </Menu.Item>
    //                 )
    //               )}
    //       </Menu.ItemGroup>
    //     ) : (
    //       <Menu.Item key={menu.key}>
    //         {menu.icon ? <Icon type={menu?.icon} /> : null}
    //         <span>{setLocale(localization, menu?.title)}</span>
    //         {menu.path ? (
    //           <Link onClick={() => closeMobileNav()} to={menu.path} />
    //         ) : null}
    //       </Menu.Item>
    //     )
    //   )}
    // </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  const [permission, setPermission] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const {userData} = useSelector(state => state.auth);
  useEffect(() => {
    setPermission(userData.roles);
    setIsAdmin(userData.is_admin);
  });
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>

        menu.submenu.length > 0 ? (

          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span className="d-flex align-items-center">
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {permission === null ? '' : menu.submenu.filter(item => permission?.groups?.some(group => (group['slug'] === item.key)) || isAdmin)
              .map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}>
                  {subMenuFirst.submenu
                    .filter(item => {
                        const m = permission?.groups?.find(group => (group['slug'] === subMenuFirst.key));
                        const s = m ? m.PermissionsPages?.findIndex(per => per['slug'] === item['key']) !== -1 : false;
                        return s || isAdmin;
                      }
                    )
                    .map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (<SideNavContent {...props} />) : (<TopNavContent {...props} />);
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
