import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import test from './testReducer';
import adminArea from './AdminArea';
import Shared from './Shared';
import StudentArea from './StudentArea';
import EmployeeArea from './EmployeeArea';
import DynamicReportArea from './DynamicReportArea'
import FeesData from './Fees'
import LmsRolesArea from './LmsRolesArea'
import Dashboard from "./Dashboard"
import LandingPageArea from './LandingPageArea'
import users from "./Users";
import sessions from "./Sessions";
import news from "./News";
import info from "./Info";


const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  users: users,
  sessions: sessions,
  news: news,
  info: info,
  // adminarea: adminArea,
  // shared: Shared,
  // studentArea: StudentArea,
  // employeeArea: EmployeeArea,
  // dynamicReportArea: DynamicReportArea,
  // feesData: FeesData,
  // lmsRolesArea: LmsRolesArea,
  // test,
  // dashboard: Dashboard,
  // landingPageArea: LandingPageArea
});

// export default reducers;

const rootReducer = (state, action) => {
    if (action.type === 'SIGNOUT_SUCCESS') {
        state = undefined
    }
    return reducers(state, action)
};

export default rootReducer