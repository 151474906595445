import { GET_INFO } from "redux-thunk/constants/Info";

const initState = {
  info: [],
};

const info = (state = initState, action) => {
  switch (action.type) {
    case GET_INFO:
      console.log("action", action);
      return {
        ...state,
        info: action?.payload?.data || [],
      };

    default:
      return state;
  }
};

export default info;
